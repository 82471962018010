/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'tfm',
    app: {
      version: '2.6.3',
      bundle: 'com.iteridea.tfm',
      appleStoreURL: '',
      googleStoreURL: '',
      url: 'https://tfm-theras-group.com',
      mediaUrl: 'https://media.tfm-theras-group.com'
    },
    api: {
      url: 'api.tfm-theras-group.com',
      stage: 'dev'
    },
    auth: {
      registrationIsPossible: false,
      singleSimultaneousSession: false,
      title: 'Theras Field Manager',
      hasIntroPage: false,
      forceLoginWithMFA: false,
      // note: the passwordPolicy should be set matching the configuration of the Cognito User Pool
      passwordPolicy: {
        minLength: 8,
        requireLowercase: false,
        requireDigits: false,
        requireSymbols: false,
        requireUppercase: false
      }
    },
    ionicExtraModules: ['common', 'variables', 'auth'],
    website: 'https://iter-idea.com'
  },
  aws: {
    cognito: {
      userPoolId: 'eu-west-1_ILGYOpCLN',
      userPoolClientId: '1uj4p12fvijqgjhleeplgg3of4'
    }
  }
};
